<template>
  <div class="client_table">
    <v-client-table :data="tableData" :columns="columns" :options="options"/>
  </div>
</template>

<script>
export default {
  name: "ClientTable",
  props: {
      columns: Array,
      tableData: Array,
      options: Object
  },
  data () {
    return {
    }
  }
};
</script>
