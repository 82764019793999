import 'babel-polyfill';
import "@/assets/scss/styles.scss";

import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import moment from "moment";
import VTooltip from 'v-tooltip';

import * as VueGoogleMaps from 'vue2-google-maps'
window.Vue = Vue;
Vue.use(VTooltip);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyD6_HNglfB_gkEzp92K_tVsEx8yMQC4rno",
    libraries: 'places,geometry', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
   autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
});

import router from "./router";

import { ClientTable } from "vue-tables-2";
import AsyncComputed from "vue-async-computed";

Vue.config.productionTip = false;

// Globalize router
Vue.prototype.router = router;

// Globalize moment
Vue.prototype.moment = moment;

// Global axios config
let env = process.env.VUE_APP_ENV;
let url;
if(window.location.hostname == "alg-track.test"){
  env = "local";
}
else if (window.location.hostname == "localhost") {
  env = "test";
} else if (window.location.hostname == "track.alg.stickoutsite.com") {
  env = "test";
} else {
  env = "prod";
}
switch (env) {
  case "local":
    url = "http://alg-laravel-api.test/api";
    break;
  case "test":
    url = "https://api.alg.stickoutsite.com/api";
    break;
  case "prod":
    url = "https://tsapi.algworldwide.com/api";
    break;
  default:
    url = "https://alg-laravel.test/api";
}

Vue.prototype.$http = axios.create({
  baseURL: url
});

Vue.use(ClientTable, {}, false, "bootstrap4");

Vue.use(AsyncComputed);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
