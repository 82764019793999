<template>
  <div id="home">
    <div class="ui grid header">
      <div class="two wide column">
        <div class="header item">
          <router-link to="/"
            ><img
              id="logo"
              src="../assets/images/ALG_logo@2x.png"
              alt="ALG Logo"
          /></router-link>
        </div>
      </div>
      <div class="three wide column computer only">
        <p id="choose_method">Choose A Tracking Method:</p>
      </div>
      <div class="three wide column computer only">
        <select
          class="ui fluid dropdown"
          id="choose_tracking_dropdown"
          v-model="trackingMethod"
        >
          <option :value="null" disabled></option>
          <option value="t">Tracking #</option>
          <option value="s">Shipper Ref</option>
          <option value="c">Consignee Ref</option>
          <option value="p">PO #</option>
        </select>
      </div>
      <div class="two wide column computer only">
        <p id="information">Information:</p>
      </div>
      <div class="four wide column computer only">
        <textarea
          id="info_textarea"
          v-model="information"
          placeholder="Enter up to 30, separated by a comma."
        ></textarea>
      </div>
      <div class="two wide column computer only">
        <div
          class="ui animated button prim"
          :class="{ loading: loading }"
          tabindex="0"
          @click="track()"
        >
          <div class="visible content">
            <i class="fas fa-map-marked-alt"></i>Track
          </div>
          <div class="hidden content"><i class="fas fa-check"></i>Submit</div>
        </div>
      </div>
    </div>
    <div class="ui grid mobile only" style="margin-left:0px;margin-right:0px">
      <div class="seven wide column">
        <p id="choose_method">Tracking Method:</p>
      </div>
      <div class="nine wide column">
        <p id="information">Information:</p>
      </div>
      <div class="seven wide column">
        <select
          class="ui fluid dropdown"
          id="choose_tracking_dropdown"
          v-model="trackingMethod"
        >
          <option :value="null" disabled></option>
          <option value="t">Tracking #</option>
          <option value="s">Shipper Ref</option>
          <option value="c">Consignee Ref</option>
          <option value="p">PO #</option>
        </select>
      </div>
      
      <div class="nine wide column">
        <textarea
          id="info_textarea"
          v-model="information"
          placeholder="Enter up to 30, one per line."
        ></textarea>
      </div>
      <div class="sixteen wide column">
        <div
          class="ui animated button prim"
          :class="{ loading: loading }"
          tabindex="0"
          @click="track()"
          style="width:100%;"
        >
          <div class="visible content">
            <i class="fas fa-map-marked-alt"></i>Track
          </div>
          <div class="hidden content"><i class="fas fa-check"></i>Submit</div>
        </div>
      </div>
    </div>
    <div class="message-container" v-if="message">
      <div class="sixteen wide column">
          {{message}}
      </div>
    </div>
    <div class="home-container">
      <div
        class="ui active centered inline massive loader"
        v-if="loading"
      ></div>

      <div v-else>
        <h1 class="ui left floated header">ALG Track</h1>
        <button
          class="ui right floated button sec"
          :class="{ loading: loading }"
          @click="downloadPdf()"
        >
          <i class="fas fa-download"></i>Download PDF
        </button>
        <v-client-table
          class="tracking_table"
          id="tracking_table"
          :columns="columns"
          :data="hawbs"
          :options="options"
        >
          <router-link
            class="tracking_link"
            :to="'/details?hawb_number=' + row.hawb_number"
            target="_blank"
            slot="hawb_number"
            slot-scope="{ row }"
            >{{ row.hawb_number }}</router-link
          >
        </v-client-table>
      </div>
    </div>

    <ErrorModal />
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
import TableExport from "tableexport";
import jsPDF from "jspdf";
import "jspdf-autotable";

import ErrorModal from "@/components/modals/ErrorModal.vue";

export default {
  components: {
    ErrorModal
  },
  data() {
    return {
      loading: false,
      data: [],
      trackingMethod: "t",
      information: null,
      message: '',
      hawbs: [],
      columns: [
        "hawb_number",
        "ship_date",
        "due_date",
        "pieces",
        "weight",
        "shipper",
        "consignee",
        "status"
      ],
      options: {
        headings: {
          hawb_number: "Tracking #",
          ship_date: "Ship Date",
          due_date: "Due Date",
          pieces: "Pieces",
          weight: "Weight",
          shipper: "Shipper City/State",
          consignee: "Consignee City/State",
          status: "Status"
        },
        sortable: [
          "hawb_number",
          "ship_date",
          "due_date",
          "pieces",
          "weight",
          "shipper",
          "consignee",
          "status"
        ],
        filterable: [
          "hawb_number",
          "ship_date",
          "due_date",
          "pieces",
          "weight",
          "shipper",
          "consignee",
          "status"
        ],
        perPage: 10,
        perPageValues: [10, 25, 50],
        texts: {
          filter: "",
          filterBy: "Search",
          count: ""
        },
        pagination: { chunk: 10, dropdown: false },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-sort-asc",
          down: "fa-sort-desc"
        },
        customSorting: {
          ship_date: function(ascending) {
            return function(a, b) {
              let dateA = new Date(a.ship_date);
              let dateB = new Date(b.ship_date);

              if (ascending) return dateA >= dateB ? 1 : -1;

              return dateA <= dateB ? 1 : -1;
            };
          },
          due_date: function(ascending) {
            return function(a, b) {
              let dateA = new Date(a.due_date);
              let dateB = new Date(b.due_date);

              if (ascending) return dateA >= dateB ? 1 : -1;

              return dateA <= dateB ? 1 : -1;
            };
          }
        },
        columnsDisplay:{
          //hawb_number: 'not_mobile',
          ship_date: 'not_mobile',
          //due_date: 'not_mobile',
          pieces: 'not_mobile',
          weight: 'not_mobile',
          shipper: 'not_mobile',
          consignee: 'not_mobile',
          //status: 'not_mobile',
        }
      }
    };
  },
  created() {
    // initiate track if query params found from alg ship
    if (this.$route.query.tracking_method && this.$route.query.information) {
      this.trackingMethod = this.$route.query.tracking_method;
      this.information = this.$route.query.information;

      this.track();
    }
    if(this.$route.query.message != ""){
      var message_arr = [];
      message_arr['ndisabled'] = 'Your notification has been disabled.';
      this.message = message_arr[this.$route.query.message];
      setTimeout(() => {
      this.message = '';
    }, 3000);
    }

    setTimeout(() => {
      $(".VueTables__search__input").attr("placeholder", "Search All...");
    }, 1000);
  },
  mounted() {
    $(".ui.fluid.dropdown").dropdown();
  },
  methods: {
    track() {
      if (this.trackingMethod == null) {
        return;
      }

      this.loading = !this.loading;

      let params = {
        tracking_method: this.trackingMethod,
        information: this.information.replace(" ", "")
      };

      this.$http
        .get("/track/quick", { params })
        .then(response => {
          this.data = response.data;

          if (this.data.tracking_number) {
            // this.router.push('/details?hawb_number='+this.data.tracking_number);

            let routeData = this.$router.resolve({
              name: "details",
              query: { hawb_number: this.data.tracking_number }
            });
            //window.open causes issues with safari so it has been replaced with window.location, the downfall of this
            //is that the window will now overwrite the browser window instead of opening up in a new window
            window.location.assign(routeData.href);
            //window.open(routeData.href, "_blank");
          } else {
            let hawbs = this.data.hawb_data.hawbs;

            this.hawbs = Object.keys(hawbs).map(function(key) {
              let hawb = hawbs[key];

              return {
                hawb_number: hawb.Hawb,
                ship_date: hawb.HAWBDate,
                due_date: hawb.DueDate,
                pieces: hawb.Pieces,
                weight: hawb.ChargeableWeight,
                shipper: hawb.ShipperCity + "/" + hawb.ShipperState,
                consignee: hawb.ConsigneeCity + "/" + hawb.ConsigneeState,
                status: hawb.Status
              };
            });
          }

          this.initiateDownloadButtons();

          this.loading = !this.loading;
        })
        .catch(errors => {
          this.errors = errors.data;
          console.log(this.errors);
          $(".ui.error_modal.modal").modal("show");
          this.loading = !this.loading;
        });
    },
    initiateDownloadButtons: function() {
      // add id to base table to allow download
      // TODO: Change to on table load
      setTimeout(() => {
        $(".VueTables__table").attr("id", "tracking_table");

        $(".VueTables__limit-field > select").addClass(
          "ui fluid limit dropdown"
        );

        $(".ui.fluid.dropdown").dropdown();

        $(".VueTables__search__input").attr("placeholder", "Search All...");
      }, 1000);
    },
    downloadExcel: function() {
      let table = document.getElementById("tracking_table");

      let excel = new TableExport(table, {
        formats: ["csv"], // (String[]), filetype(s) for the export, (default: ['xlsx', 'csv', 'txt'])
        filename: "Tracking", // (id, String), filename for the downloaded file, (default: 'id')
        bootstrap: false, // (Boolean), style buttons using bootstrap, (default: true)
        exportButtons: false, // (Boolean), automatically generate the built-in export buttons for each of the specified formats (default: true)
        trimWhitespace: true, // (Boolean), remove all leading/trailing newlines, spaces, and tabs from cell text in the exported file(s) (default: false)
        sheetname: "Tracking" // (id, String), sheet name for the exported spreadsheet, (default: 'id')
      });

      let exportData = excel.getExportData()["tracking_table"]["csv"];

      excel.export2file(
        exportData.data,
        exportData.mimeType,
        exportData.filename,
        exportData.fileExtension
      );
    },
    downloadPdf: function() {
      alert("pdf download");
      const doc = new jsPDF();

      doc.autoTable({
        html: "#tracking_table"
      });

      doc.save("tracking.pdf");
    }
  }
};
</script>

<style lang="scss">
.message-container{
    margin-top: 15px;
    background-color: rgb(214 27 68 / 60%);
    color: white;
    text-align: center;
}

#home {
  input,
  select,
  textarea {
    border-radius: 8px;
    border: 1px solid $algdarkblue25opac !important;
    width: 100%;
  }

  .ui.header {
    font-weight: 400 !important;
    font-size: 16px;
  }

  .ui.grid.header {
    border-radius: 0;
    min-height: 65px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0;

    .ui.button.prim {
      margin-top: 15px;
    }

    textarea {
      font-size: 14px;
      color: $alggrey;
      padding: 11px 0px 10px 16px;
      border: $darkblueborder !important;
      border-radius: 4px;
      margin-left: 10px !important;
      width: 100%;
      height: 56px;
      padding-top: 17px;
    }

    #choose_method {
      padding: 20px 0px 0px 0px;
    }

    #information {
      padding: 20px 0px 0px 45px;
    }

    .ui.fluid.dropdown.selection {
      margin-top: 5px;
      padding-top: 20px;
      height: 56px;
      border: $darkblueborder !important;
      color: $alggrey !important;

      i {
        padding-top: 20px;
        color: $algdarkblue;
      }
    }

    #info_textarea {
      margin-top: 5px;
    }
  }

  .home-container {
    margin: 40px 40px 0px 40px;
  }

  .ui.menu {
  }

  .header.item:before {
    display: none;
  }

  .three.wide.column.pdf {
    text-align: right;
  }

  .VueTables__limit {
    float: unset !important;
  }

  select {
    width: 125px;
    height: 40px;
  }

  .VueTables__search__input {
    border-radius: 20px !important;
    position: absolute;
    right: 120px;
    width: 350px;
    height: 40px;
    border: $darkblueborder !important;
    padding-left: 20px;
  }
  @media only screen and (max-width: 600px) {
    .VueTables__search,.VueTables__search-field {
      width:100%
    }
    .VueTables__search__input {
      width:100%;
      position:relative;
      right:0;
    }
    .tracking_table > .row{
      width:100%;
    }
    #info_textarea{
      height:40px;
      padding:7px;
    }
    .home-container {
      margin: 40px 20px 0px 20px;
    }
  }

  .tracking_table {
    border: none;
    box-shadow: none;

    .tracking_link {
      color: $algdarkblue !important;
      text-decoration: underline;
    }

    thead {
      tr {
        th {
          color: $algdarkblue;
          background-color: $algdarkbluetenopac;
          border: none;
        }

        th:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        th:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    tbody {
      tr {
        background-color: #fff;

        td {
          border: none;
        }
      }
    }
  }
}
</style>
