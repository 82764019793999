<template>
  <div class="ui error_modal modal">
    <div class="header">Error <i class="close icon" @click="close()"></i></div>
    <div class="content">
      <div class="ui two column grid">
        <div class="column">
          There was an error finding your information.
        </div>
        <div class="column">
          <img class="logo" src="../../assets/images/ALG_logo@2x.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
export default {
  props: {
    errors: Object
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    close: function() {
      $(".ui.error_modal.modal").modal("hide");
      window.location.replace("/");
    }
  },
  computed: {}
};
</script>

<style lang="scss">
.ui.error_modal.modal {
  margin: 13rem auto !important;
  padding-left: 40px;
  padding-top: 40px;

  .header {
    color: $algdarkblue !important;
    border-bottom: none !important;

    .close.icon {
      color: $algdarkblue !important;
      padding-right: 40px;
    }
  }

  .logo {
    display: block;
    margin: auto;
  }

  button {
    margin-top: 20px;
    margin-right: 0;
  }
}
</style>
