<template>
  <div class="accordion client_table">
    <div class="ui header" v-on:click="toggle">
      <slot name="header">HINT</slot>
      <i
        class="fa fa-2x fa-angle-down header-icon"
        v-bind:class="{ rotate: show }"
      ></i>
    </div>
    <transition
      name="accordion"
      v-on:before-enter="beforeEnter"
      v-on:enter="enter"
      v-on:before-leave="beforeLeave"
      v-on:leave="leave"
    >
      <div class="body" v-show="show">
        <div class="body-inner">
          <v-client-table
            :data="tableData"
            :columns="columns"
            :options="options"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ClientTable",
  props: {
    columns: Array,
    tableData: Array,
    options: Object,
},
  data() {
    return {
      show: false,
    };
  },
  created() {},
  methods: {
    toggle: function() {
      this.show = !this.show;
    },
    // enter: function(el, done) {
    //   $(el).slideDown(150, done);
    // },
    // leave: function(el, done) {
    //   $(el).slideUp(150, done);
    // },
    beforeEnter: function(el) {
      el.style.height = "0";
    },
    enter: function(el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave: function(el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave: function(el) {
      el.style.height = "0";
    }
  }
};
</script>
